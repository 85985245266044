/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ReportStatus {
  Completed = "Completed",
  Errored = "Errored",
  InProgress = "InProgress",
  Pending = "Pending",
}

export interface AccountIntegrationInput {
  venueId: string;
  userId: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  country?: string | null;
  phone?: string | null;
  type?: string | null;
  businessName?: string | null;
  dateOfBirth?: DateOfBirthInput | null;
  description?: string | null;
  redirectUrl?: string | null;
  additionalStateSegments?: string[] | null;
  setVenueDefault?: boolean | null;
  integrationSettings?: AccountSettingsInput | null;
}

/**
 * Details on when funds from `Charge`s are available, and when they are paid out to an `ExternalAccount`
 */
export interface AccountPayoutScheduleInput {
  delayDays?: number | null;
  interval?: string | null;
  monthlyAnchor?: number | null;
  weeklyAnchor?: string | null;
}

/**
 * Settings specific to the `Account`'s `Payout`s
 */
export interface AccountPayoutSettingsInput {
  schedule?: AccountPayoutScheduleInput | null;
}

/**
 * Options for customizing how the `Account` functions within Stripe
 */
export interface AccountSettingsInput {
  payouts?: AccountPayoutSettingsInput | null;
}

/**
 * `DateOfBirth` input model
 */
export interface DateOfBirthInput {
  day: number;
  month: number;
  year: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
