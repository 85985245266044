import cx from 'classnames'
import React, { ReactNode } from 'react'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import * as styles from './panel.module.less'
import { ClassNamesProps } from 'src/types'

export interface TitleProps {
  end?: boolean
  className?: string
}

interface PanelSubcomponents {
  Title: React.FC<TitleProps>
}

type ClassKeys = 'wrapper' | 'container'

interface Props extends SpacingProps, ClassNamesProps<ClassKeys> {
  title?: ReactNode
  headerEndContent?: ReactNode
  floatingTitle?: string
  testId?: string
  extendedPadding?: boolean
}

const Panel: React.FC<Props> & PanelSubcomponents = ({
  children,
  floatingTitle,
  title,
  headerEndContent,
  spacing = { margins: { md: 'vertical' } },
  extendedPadding,
  testId,
  classNames
}) => {
  const classes = useSpacing(spacing)
  return (
    <section className={cx(classes, classNames?.wrapper)} data-testid={testId}>
      <FloatingTitle title={floatingTitle} />
      <div
        className={cx(
          styles.panel,
          {
            [styles.extendedPadding]: extendedPadding
          },
          classNames?.container
        )}
      >
        {(title || headerEndContent) && (
          <header className={styles.panelHeader}>
            <div>{title && <PanelTitle>{title}</PanelTitle>}</div>
            <div className={styles.headerEnd}>{headerEndContent}</div>
          </header>
        )}
        {children}
      </div>
    </section>
  )
}

const FloatingTitle: React.FC<{ title?: string }> = ({ title }) => {
  return title ? <h2 className={styles.floatingTitle}>{title}</h2> : null
}

const PanelTitle: PanelSubcomponents['Title'] = ({ className, children }) => {
  return <h2 className={cx(className, styles.panelTitle)}>{children}</h2>
}

interface PanelGroupProps {
  title?: string
}

export const PanelGroup: React.FC<PanelGroupProps> = ({ title, children }) => {
  return (
    <section className={styles.panelGroup}>
      <FloatingTitle title={title} />
      {children}
    </section>
  )
}

Panel.Title = PanelTitle

export default Panel
