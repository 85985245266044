import graphql from 'graphql-tag'

export const GENERATE_CSV_REPORT = graphql`
  mutation GenerateReport(
    $query: String!
    $endpoint: String!
    $outputFormat: FormatEnum!
    $paginator: AddReportPaginator
    $queryVariablesJSON: String
    $csvTransforms: [CSVTransform!]
    $csvFormatOptions: CSVFormatOptions
  ) {
    addReport(
      input: {
        query: $query
        graphqlEndpoint: $endpoint
        outputFormat: $outputFormat
        paginator: $paginator
        queryVariablesJSON: $queryVariablesJSON
        csvTransforms: $csvTransforms
        csvFormatOptions: $csvFormatOptions
      }
    ) {
      id
      url
    }
  }
`

export const CHECK_UPLOAD_STATE = graphql`
  query CheckUploadState($reportId: ID!) {
    reportMetadata(id: $reportId) {
      uploaded
      status {
        state
        message
      }
      url
      id
    }
  }
`
