import React, { ReactNode } from 'react'
import * as styles from './page-header.module.less'
import classNames from 'classnames'

interface Props {
  title: ReactNode
  size?: 'sm' | 'md'
  noPadding?: boolean
}

const PageHeader: React.FC<Props> = ({ title, size = 'sm', noPadding }) => {
  return (
    <h2
      className={classNames(styles.title, styles[size], {
        [styles.titlePadding]: !noPadding
      })}
    >
      {title}
    </h2>
  )
}

export default PageHeader
