import { useMutation, useQuery } from '@apollo/client'
import { BodyLarge, H1, H4 } from '@clubspark-react/clubspark-react-tools'
import { Grid } from '@material-ui/core'
import { print } from 'graphql'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { paymentClient } from 'src/apollo/client'
import { useOrgId } from 'src/apollo/local-state'
import { getEnvConfig } from 'src/config/config'
import { GetAccountPayouts, GetAccountPayoutsVariables } from 'src/graphql-types/GetAccountPayouts'
import {
  GetReportRecordsPaged,
  GetReportRecordsPagedVariables
} from 'src/graphql-types/getReportRecordsPaged'
import { GetReportStatus, GetReportStatusVariables } from 'src/graphql-types/getReportStatus'
import { ReportStatus } from 'src/graphql-types/globalPaymentsTypes'
import {
  RunPayoutTransactionReport,
  RunPayoutTransactionReportVariables
} from 'src/graphql-types/runPayoutTransactionReport'
import AdminTable, { DataCols } from '../admin-table/admin-table'
import Button from '../button/button'
import EmptyState from '../empty-state/empty-state'
import GenerateReport, { CSVValueTransform } from '../generate-report/generate-report'
import PageHeader from '../page-header/page-header'
import Panel, { PanelGroup } from '../panel/panel'
import Spinner from '../spinner/spinner'
import { useControlledQuery } from '../table-controls/table-controls'
import { Body } from '../typography/typography'
import { PageMaxWidth } from '../util-components/util-components'
import {
  GENERATE_TRANSACTION_REPORT,
  GET_ACCOUNT_PAYOUTS,
  TRANSACTION_REPORT_PAGED,
  TRANSACTION_REPORT_STATUS
} from './payouts-report-transactions-queries'
import * as styles from './payouts-report-transactions.module.less'

interface PayoutsReportTransactionsProps {
  payoutsReportId: string
}

interface Payout {
  id: string
  date: string
  sourceTransaction?: Payout
  metadata: { key: string; value: string }[]
  created: number
  amount: number
  type: string
  net: number
  description: string
}

const PayoutsReportTransactions: React.FC<PayoutsReportTransactionsProps> = ({
  payoutsReportId
}) => {
  const { t } = useTranslation()
  const venueId = useOrgId()
  const transactionReportId = `transactions-${payoutsReportId}`

  const {
    data: statusData,
    loading: loadingStatus,
    stopPolling,
    startPolling,
    refetch: refetchStatus
  } = useQuery<GetReportStatus, GetReportStatusVariables>(TRANSACTION_REPORT_STATUS, {
    client: paymentClient,
    variables: { reportId: transactionReportId, venueId }
  })

  const [generateReport, { loading: generateReportLoading }] = useMutation<
    RunPayoutTransactionReport,
    RunPayoutTransactionReportVariables
  >(GENERATE_TRANSACTION_REPORT, {
    client: paymentClient,
    variables: { payoutId: payoutsReportId, venueId },
    onCompleted: () => refetchStatus()
  })

  const orgId = useOrgId()

  const { data: payoutsData } = useQuery<GetAccountPayouts, GetAccountPayoutsVariables>(
    GET_ACCOUNT_PAYOUTS,
    {
      client: paymentClient,
      variables: { venueId: orgId }
    }
  )
  const totalReceived = useMemo(
    () => payoutsData?.venueDefaultAccount?.payouts?.find(po => po.id === payoutsReportId)?.amount,
    [payoutsData]
  )

  const reportStatus = statusData?.getReportStatus?.reportStatus

  useEffect(() => {
    if (reportStatus) {
      if (reportStatus === ReportStatus.InProgress || reportStatus === ReportStatus.Pending) {
        startPolling(1500)
      } else {
        stopPolling()
      }
    }
  }, [stopPolling, startPolling, reportStatus])

  return (
    <PageMaxWidth>
      <PageHeader title={t('payouts report')} />
      <PanelGroup>
        <Panel>
          {loadingStatus ? (
            <Spinner />
          ) : (
            <Grid container justify="space-between" alignItems="center" alignContent="center">
              <Grid container item xs={12} md={7} alignItems="center" justify="space-between">
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container alignContent="center" alignItems="center" spacing={5}>
                    <Grid container item xs={9} direction="column">
                      <BodyLarge>{t('total received')}</BodyLarge>
                      <H1>{`$${totalReceived ? `${(totalReceived / 100).toFixed(2)}` : 0}`}</H1>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {statusData && reportStatus !== ReportStatus.Completed && (
                <Button
                  loading={!!reportStatus || generateReportLoading}
                  onClick={() => generateReport()}
                >
                  {t('generate payouts report')}
                </Button>
              )}
            </Grid>
          )}
        </Panel>
        <ReportProgressPanel reportStatus={reportStatus} loading={loadingStatus} />
        {reportStatus === ReportStatus.Completed && (
          <TransactionsDataPanel transactionReportId={transactionReportId} />
        )}
      </PanelGroup>
    </PageMaxWidth>
  )
}

interface ReportProgressPanelProps {
  reportStatus?: ReportStatus
  loading?: boolean
}

const ReportProgressPanel: React.FC<ReportProgressPanelProps> = ({ reportStatus, loading }) => {
  const { t } = useTranslation()
  if (reportStatus === ReportStatus.Completed) return null
  return (
    <Panel>
      <div className={styles.reportProgress}>
        <Body size={'md'}>
          {loading && <Spinner />}
          {!loading && !reportStatus && t('generate report prompt')}
          {reportStatus === ReportStatus.Errored && t('generate report error')}
          {reportStatus && [ReportStatus.InProgress, ReportStatus.Pending].includes(reportStatus) && (
            <>
              <Spinner />
              {t('generating report')}
            </>
          )}
        </Body>
      </div>
    </Panel>
  )
}

interface TransactionsDataPanelProps {
  transactionReportId: string
}

const TransactionsDataPanel: React.FC<TransactionsDataPanelProps> = ({ transactionReportId }) => {
  const { t } = useTranslation()
  const venueId = useOrgId()

  const getTotalItems = useCallback(
    (d: GetReportRecordsPaged) => +(d.getReportRecordsPaged?.totalRecords ?? 0),
    []
  )

  const transformVariables = useCallback(vars => {
    return { ...vars, skip: vars.offset }
  }, [])

  const { data, loading: payoutLoading, controlProps } = useControlledQuery<
    GetReportRecordsPaged,
    GetReportRecordsPagedVariables & { offset: number }
  >(TRANSACTION_REPORT_PAGED, {
    client: paymentClient,
    variables: { venueId, reportId: transactionReportId },
    getTotalItems,
    transformVariables
  })

  const PAYMENT_TYPES = {
    tournamentFee: t('tournament sanction fee'),
    additionalBasketPayment: t('additional basket payment')
  }

  const TRANSACTION_TYPES = {
    charge: t('charge'),
    refund: t('refund')
  }

  /* ITA and USTA have discrepancies in where transaction data is stored. For
   * USTA we have data stored on the sourceTransaction, for ITA it is on the
   * transaction directly. These should be aligned if possible but until then,
   * the following methods are used to locate the correct data.
   ************************************************************/
  const getTransaction = useCallback((payout: Payout) => {
    return payout.sourceTransaction ?? payout
  }, [])
  const reportRecords = data?.getReportRecordsPaged?.records as Payout[] | undefined
  const transactionPath = useCallback(
    (strings: TemplateStringsArray) => {
      if (reportRecords?.[0]?.sourceTransaction) return `sourceTransaction.${strings.join()}`
      return strings.join()
    },
    [reportRecords]
  )
  /***********************************************************/

  const tableMetadataField = useCallback(
    (key: string) => (payout: Payout) => {
      const metadata = getTransaction(payout)?.metadata ?? []
      return metadata.find(md => md.key === key)?.value ?? (t('n/a') )
    },
    [t, getTransaction]
  )

  const cols: DataCols<Payout> = useMemo(
    () => [
      {
        key: 'date',
        title: t('date'),
        getValue: p => {
          const created = getTransaction(p)?.created
          return created ? t('payout transaction date', { date: moment.unix(created) }) : t('n/a')
        }
      },
      {
        key: 'tournamentName',
        title: t('description'),
        getValue: tableMetadataField('tournamentName')
      },
      { key: 'venueName', title: t('venue'), getValue: tableMetadataField('venueName') },
      {
        key: 'type',
        title: t('payment type'),
        getValue: p => {
          const val = tableMetadataField('paymentType')(p)
          return PAYMENT_TYPES[val] ?? val
        }
      },
      {
        key: 'paymentType',
        title: t('transaction type'),
        getValue: p => {
          const type = getTransaction(p)?.type
          return (type && TRANSACTION_TYPES[type]) ?? t('n/a')
        }
      },
      {
        key: 'amount',
        title: t('amount'),
        getValue: p => {
          const amount = getTransaction(p)?.amount ?? 0
          return `$${(amount / 100).toFixed(2)}`
        }
      },
      {
        key: 'net',
        title: t('net'),
        getValue: p => {
          const net = getTransaction(p)?.net ?? 0
          return `$${(net / 100).toFixed(2)}`
        }
      },
      {
        key: 'userName',
        title: t('customer'),
        getValue: tableMetadataField('userName')
      }
    ],
    [t]
  )

  const csvMetadataField = useCallback(
    (metadataKey: string, label: string, otherParams?: any) => {
      return {
        key: transactionPath`metadata`,
        label,
        transforms: [
          {
            operation: CSVValueTransform.ARRAY_FILTER,
            parameters: [
              { key: 'fieldPath', value: 'key' },
              { key: 'compareValue', value: metadataKey }
            ]
          },
          {
            operation: CSVValueTransform.ARRAY_JOIN,
            parameters: [{ key: 'fieldPath', value: 'value' }]
          }
        ],
        ...(otherParams ?? {})
      }
    },
    [transactionPath]
  )

  return (
    <Panel>
      {data?.getReportRecordsPaged?.records?.length === 0 ? (
        <EmptyState title={t('no payout reports')} icon={'xl-document'} />
      ) : (
        <>
          <Grid container justify="space-between" alignItems="flex-start">
            <H4 spacing={{ margins: { xs: 'bottom' } }}>{t('payouts')}</H4>
            <GenerateReport
              reportQuery={print(TRANSACTION_REPORT_PAGED)}
              reportQueryEndpoint={getEnvConfig().PAYMENT_GQL_URL}
              reportQueryVariables={{ venueId, reportId: transactionReportId }}
              paginator={{ rootFieldPath: 'getReportRecordsPaged.records', pageSize: 100 }}
              csvFormatOptions={{ disableUnwind: true }}
              csvTransforms={[
                {
                  key: 'created',
                  label: t('created at'),
                  transform: CSVValueTransform.TO_DATE_TIME
                },
                { key: transactionPath`sourceId`, label: t('transaction id') },
                {
                  key: 'amount',
                  label: t('amount'),
                  transform: CSVValueTransform.DIVIDE_100
                },
                { key: 'currency', label: t('currency') },
                {
                  key: transactionPath`type`,
                  label: t('transaction type'),
                  valueMap: [
                    { in: 'charge', out: TRANSACTION_TYPES['charge'] },
                    { in: 'refund', out: TRANSACTION_TYPES['refund'] }
                  ]
                },
                csvMetadataField('paymentType', t('payment type'), {
                  valueMap: [{ in: 'tournamentFee', out: PAYMENT_TYPES['tournamentFee'] }]
                }),
                csvMetadataField('userName', t('customer')),
                csvMetadataField('venueName', t('organization')),
                csvMetadataField('region', t('region')),
                csvMetadataField('tournamentName', t('tournament name')),
                csvMetadataField('category', t('tournament category')),
                csvMetadataField('tournamentLevel', t('tournament level')),
                csvMetadataField('tournamentStartDate', t('tournament start date')),
                csvMetadataField('tournamentId', t('tournament id')),
                csvMetadataField('basketId', t('basket id'))
              ]}
            />
          </Grid>
          <AdminTable
            controls={controlProps}
            columns={cols}
            data={data?.getReportRecordsPaged?.records}
            loading={payoutLoading}
          />
        </>
      )}
    </Panel>
  )
}

export default PayoutsReportTransactions
