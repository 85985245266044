import React from 'react'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import Icon, { IconProps } from '../icon/icon'
import { BodyLarge, H3 } from '../typography/typography'
import cx from 'classnames'
import * as styles from './empty-state.module.less'
import Button from '../button/button'

interface EmptyStateProps extends SpacingProps {
  title: string
  subtitle?: string | null
  icon: IconProps['name']
  action?: {
    text: string
    onClick: () => void
  }
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  icon,
  spacing = { margins: { lg: 'all' } },
  subtitle,
  action,
  children
}) => {
  const spacingClass = useSpacing(spacing)
  return (
    <div className={cx(styles.emptyState, spacingClass)}>
      <Icon name={icon} className={styles.icon} />
      <H3>{title}</H3>
      <BodyLarge light spacing={{ margins: { sm: 'vertical' } }}>
        {subtitle}
      </BodyLarge>
      {action && (
        <Button onClick={action.onClick} size="sm" spacing={{ margins: { sm: 'bottom' } }} linkStyle>
          {action.text}
        </Button>
      )}
      {children}
    </div>
  )
}

export default EmptyState
