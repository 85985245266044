import graphql from 'graphql-tag'

export const GET_ACCOUNT_PAYOUTS = graphql`
  query GetAccountPayouts($venueId: String!) {
    venueDefaultAccount(venueId: $venueId) {
      payouts {
        id
        amount
      }
    }
  }
`

export const GENERATE_TRANSACTION_REPORT = graphql`
  mutation RunPayoutTransactionReport($payoutId: ID!, $venueId: ID!) {
    runPayoutTransactionReport(payoutId: $payoutId, venueId: $venueId) {
      reportId
      reportStatus
    }
  }
`

export const TRANSACTION_REPORT_STATUS = graphql`
  query GetReportStatus($reportId: String!, $venueId: ID!) {
    getReportStatus(reportId: $reportId, venueId: $venueId) {
      reportId
      reportStatus
    }
  }
`

export const TRANSACTION_REPORT_PAGED = graphql`
  query GetReportRecordsPaged($reportId: String!, $venueId: ID!, $limit: Int!, $skip: Int!) {
    getReportRecordsPaged(
      reportId: $reportId
      venueId: $venueId
      pageArgs: { skip: $skip, limit: $limit }
    ) {
      reportId
      totalRecords
      records
    }
  }
`
